

import React from "react";
import styled from "styled-components";
import LinkButton from "./pohrby/LinkButton";
import Telefon from "../assets/img/telefon.svg";
import TelefonWhite from "../assets/img/telefonWhite.svg";


const CtaStripInner = styled.div`
  background: #F4F3F0;
  padding: 60px 0;
  text-align: center;

  h2 {
    font-family: Ivar;
    font-size: 25px;
    color: #000000;
    text-align: center;
    line-height: 34px;
    margin-bottom: 30px;

    @media (min-width: 900px) {
      font-size: 32px;
      line-height: auto;
      margin-bottom: 35px;
    }
  }

  .linkButton {
    a {
      margin: 0 auto;
      display: block;
    }
  }

  .underButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    @media (max-width: 560px) { 
      display: block;
      text-align: center;
    }

    .neboZavolejte {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 20px;
      margin-right: 30px;

      @media (max-width: 899px) { 
        width: fit-content;
        margin-left: 0;
      }

      @media (max-width: 560px) { 
        margin: 0 auto;
        margin-top: 20px;
      }

      span {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        text-align: right;
        line-height: 28px;
      }

      img {
        margin: 0 10px;
        margin-left: 12px;
        width: 18px;
        height: 18px;
      }

      a {
        margin: 0;
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #000000;
        text-align: center;
        line-height: 28px;
        text-decoration: underline;
        background: none;
      }
    }

    .nonstop {
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 15px;
      width: fit-content;

      @media (max-width: 899px) {
        margin-left: 0;
      }

      @media (max-width: 560px) { 
        margin: 0 auto;
        margin-top: 10px;
      }

      .greenDot {
        background: #49B25D;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        animation: pulse 2s infinite;
        display: inline-block;
        vertical-align: middle;
      }

      @keyframes pulse {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.25;
        }

        100% {
          opacity: 1;
        }
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #48B15C;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
`;

const CtaStrip = () => {
  
  return (
    <CtaStripInner>
      <h2>Organizar un funeral ahora</h2>
      <LinkButton to="/nabidka/dotaznik" text="Organizar un funeral ahora" />
      <div className="underButton">
        <div className="neboZavolejte">
          <span>O llame a</span>
          <img src={Telefon} alt="Telefon" />
          <a href="tel:315558136">XXX XXX XXX</a>
        </div>

        <div className="nonstop">
          <div className="greenDot"></div>
          <span>Asistencia 24/7</span>
        </div>
      </div>
    </CtaStripInner>
  );
}

export default CtaStrip;
