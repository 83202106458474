import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import { StaticQuery, graphql, Link } from "gatsby"
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';


import Stars from "../../assets/img/recenzeHvezdy.svg";
import Next from "../../assets/img/next.svg";
import Prev from "../../assets/img/prev.svg";
import LinkButton from "./LinkButton";

SwiperCore.use([Navigation]);

const recenze = [
  {
    recenze: "Nejsem typ člověka, který píše recenze, nicméně v tomto případě je to moje povinnost. Jednala jsem od samého začátku s Paní Annou Janoštíkovou (a to jen s ní a až do chvíle, kdy mi dokonce zemřelého dědečka v urně osobně přivezla) a v životě semnou nejednal nikdo tak ochotně a mile. Pohřeb jsem řešila poprvé, Paní mě krok po kroku vedla, co zajistit, aby vše proběhlo v pořádku. Vše mi moc hezky vysvětlila a dokonce mě podpořila při nepěkném jednání s mou osobou u jiné pohřební služby. Ve finále mě zbavila veškerého stresu, protože VŠE zařídila s jejím týmem a já se nemusela o nic starat. Tohle je luxusní servis za směšnou cenu (nenašla jsem na internetu žádnou levnější pohřeb. službu!) a to pro mě, v takovou smutnou situaci v kombinaci s finanční tísní způsobené vládními omezeními kvůli covidu, bylo prvořadé! Paní Anička je anděl, který má srdce na svém místě a svět - obvzlášť pohřeb. služby by měly mít jen personál Aniččina charakteru! :) děkuji Vám.",
    jmeno: "Ivana H.",
    mesto: "Praha",
  },
  {
    recenze: "Asi není pro poslední rozloučení nejvhodnější pohřeb bez obřadu, ale někdy to tak prostě z různých důvodů je. Pak v tomto případě zvolit společnost Goodbye je ta správná volba. Určitě doporučuji, vše proběhlo dle domluvy.s",
    jmeno: "Monika P.",
    mesto: "Liberec",
  },
  {
    recenze: "Chtěl bych poděkovat za služby které jsem si u vás objednal. V situaci jako je odchod nekoho blízkého je strašně složitý všechno zařídit…Nemyslim so ze birokraticky, ale nemáte úplně chuť, náladu ale hlavně čas to řešit..Příjemný to ani tak rozhodně není, ale pani Janoštíková mi to dost usnadnila svým přístupem.Ten bych opravdu rad vyzdvihl. Trpělivost jakou má s lidmi kteří jsou v tento moment dost zmatení je obdivuhodná i přesto že je očekávaná. Pomohli jste mi a za to obrovské díky.",
    jmeno: "Radek Z.",
    mesto: "Praha",
  },
  {
    recenze: "Byli jsme maximálně spokojeni. Přístup zřízeců sluzby byl maximálně profesionalní a potěšilo mě i jednání a komunikace s paní na lince a paní co tatínka přivezla.",
    jmeno: "Jiřina Ž.",
    mesto: "Praha",
  },
  {
    recenze: "S Vašimi službami jsem byla naprosto spokojená. Od prvního kontaktu s Vámi musím ocenit maximální ochotu, profesionalitu a individuální přístup. Moc děkuji.",
    jmeno: "Andrea R.",
    mesto: "Praha",
  },
  {
    recenze: "Mám jen skvělé zkušenosti se společnosti Goodbye a doporučila bych je každému kdo hledá diskrétní a jednoduchou kremaci bez komplikací. Rozloučení s blízkým člověkem je jedna z nejtěžších a nejstresujich věci (u nás to byl tatinek a manžel) a Goodbye s paní Janoštíkovou nám ohromě pomohli s navigací celého procesu. (Paní Janoštíková nám také odpověděla na všechny naše otázky a mohli jsme ji zavolat kdykoli). Nejlepší bylo, že v době koronaviru jsme mohli zařídit kremaci hned po internetu bez zbytečných starosti a až se situace zlepší můžeme rozloučení pojmout po svém. Tohle jsem velice ocenila a zjednodušilo nám to situaci. Mockrát děkuji za vše v této těžké životní situaci.",
    jmeno: "Markéta M.",
    mesto: "Praha",
  },
  {
    recenze: "Vašich služeb jsme s rodinou využili pro našeho dědečka. Paní Janoštíková byla po celou dobu velice nápomocná a hlavně nám pomohla se zorientovat ve všem, co musíme zařídit. Jsem moc ráda, že jsme na narazili právě na Vás.",
    jmeno: "Iveta L.",
    mesto: "Praha",
  },
  {
    recenze: "Vřele děkuji za vlídný a lidský přístup, se kterým jste mi pomohli zařídit pohřeb pro maminku. Vše proběhlo rychle, důstojně a za příznivou cenu.",
    jmeno: "Karel M.",
    mesto: "Praha",
  },
  {
    recenze: "Ve chvíli, kdy nám z nemocnice neposkytli skoro žádné info, byli zaměstnanci Goodbye maximálně ochotní, všechno nám vysvětlili a pomohli nám zvládnout situaci, která byla od začátku pro celou rodinu velkým neštěstím. Všem našim požadavkům vyhověli vstřícně a rychle kremaci zařídili. Děkujeme.",
    jmeno: "Jana Č.",
    mesto: "Praha",
  },
  {
    recenze: "Přestože jsem byla nucena řešit nadmíru těžkou situaci, mohu s čistým svědomím říct, že služby společnosti Goodbye byly naprosto perfektní a při jednání zbytečně nejitřily už tak zjitřené emoce. Děkuji ze srdce.",
    jmeno: "Květa Č.",
    mesto: "Praha",
  },
  {
    recenze: "Přestože jsem do společnosti Goodbye volala v sobotu v pozdních večerních hodinách, paní Janoštíková byla velice milá a ochotná a poskytla mi potřebné informace. Vše zařídila, do 14 dní mi přivezla urnu, úmrtní list jsem poštou obdržela týden po úmrtí maminky. Děkuji",
    jmeno: "Zlata Š.",
    mesto: "Praha",
  },
  {
    recenze: "Velmi příjemné jednání v této nezavidihodné situaci. O vše se postarali, vše zařídili. Prakticky jsem vše vyřizovala přes email.",
    jmeno: "Simona R.",
    mesto: "Praha",
  },
  {
    recenze: "Citlivý přístup, pro mě nejdůležitější.",
    jmeno: "Monika P.",
    mesto: "Brno",
  },
  {
    recenze: "Velmi rychlé a empatické chování, pomohou s vytvořením parte v digitální podobě, dovoz urny až domů. Nejlepší cena na trhu! Jsem opravdu velmi spokojena. Měla jsem zkušenost s jinou pohřební službou a to byla katastrofa. Mohu jen doporučit.",
    jmeno: "Svatava Š.",
    mesto: "Praha",
  },
  {
    recenze: "Zařizování pohřbu v situaci, kdy člověk trpí, není jednoduché. Hledala jsem profesionální pohřební službu s individuálním a osobním přístupem, kde budou mít pochopení pro mé požadavky, vyjdou mi ve všem vstříc a budou jednat s úctou. U paní Janoštíkové jsem našla vše, co jsem hledala - ochotu, pochopení, rychlost zařízení, podporu... Děkuji za usnadnění těžké situace.",
    jmeno: "Lada D.",
    mesto: "Praha",
  },
  {
    recenze: "Lidský a profesionální přístup v těžkých chvílích. Od prvního telefonátu vše probíhalo vstřícně a ochotně. Maximální spokojenost a rychlost vyřízení všeho online.",
    jmeno: "Michal a Tomáš",
    mesto: "Brno",
  },


  {
    recenze: `ABSOLUTNÍ SPOKOJENOST!!! I při tak smutné záležitosti byla "radost" ji se slečnou Aničkou vyřizovat. Velice příjemná, milá, vstřícná, ochotná trpělivá ... Vaše společnost mi byla doporučena známými a byla jsem za to velmi ráda. Zkusila jsem kontaktovat i jiné společnosti - nesrovnatelné!!! Ještě jednou: naprostá spokojenost!!!`,
    jmeno: "Ing. Jana V.",
    mesto: "Ústí nad Labem",
  },

  {
    recenze: "Chtěl bych poděkovat za služby které jsem si u vás objednal. V situaci jako je odchod nekoho blízkého je strašně složitý všechno zařídit…Nemyslim si ze birokraticky, ale nemáte úplně chuť, náladu ale hlavně čas to řešit..Příjemný to ani tak rozhodně není, ale pani Janoštíková mi to dost usnadnila svým přístupem.Ten bych opravdu rad vyzdvihl. Trpělivost jakou má s lidmi kteří jsou v tento moment dost zmatení je obdivuhodná i přesto že je očekávaná. Pomohli jste mi a za to obrovské díky",
    jmeno: "Radek Z.",
    mesto: "Praha",
  },
  {
    recenze: "Byli jsme se službami velice spokojeni - rychlost, profesionalita, ochota a vstřícnost. A hlavně - cena byla skutečně finální, žádné načítání dalších poplatků apod. Tento přístup byl pro nás v situaci, kdy člověk truchlí a přitom musí vyřizovat spoustu věcí, skutečnou pomocí. Děkujeme!",
    jmeno: "Alena K.",
    mesto: "Brno",
  },
  {
    recenze: "Mile mne potěšilo, že konečně existuje pohřební služba, ve které existuje lidskost, kde má člověk pocit, že je to skutečně služba pro něj a ne proti němu. V roce 2020 jsem bohužel měla zkušenost s Pohřební službou města Brna a vůbec jsem nedokázala pochopit, co je to za prapodivnou službu. Vlastně šlo spíš jen o byznys. Bylo mi z toho smutno. Přišlo mi neuvěřitelné, že v dnešní době stále takovéhle služby existují. Ještě horší ale bylo to, že k takovýmto pohřebním službám neexistovala žádná jiná varianta. Jediná aternativa byla společnost Ke kořenům, která je mi velmi sympatická, ale pro moje prarodiče až příliš jiná a na to neměli odvahu. Ani nevíte, jak jsem byla vděčná, když jsem ve všem tom smutku zjistila, že existuje Goodbye. Pro mne a celou rodinu to byla velká úleva. Cítíli jsme, že se s vámi dá domluvit na všem, a to je hrozně příjemné. Žádné naše přání pro vás nebylo nereálné, všechno bylo tak jak mělo být. Děkuji za mne a celou moji rodinu.",
    jmeno: "Markéta L.",
    mesto: "Brno",
  },
]


const ReviewsCremationInner = styled.section`
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 60px 0;

  @media (max-width: 899px) {
    padding: 40px 0;
  }

  h2 {
    margin: 0 auto;
    font-family: Ivar;
    font-size: 25px;
    color: #fff;
    text-align: center;
    line-height: 34px;
    margin-bottom: 35px;
    width: calc(100% - 50px);
    z-index: 1;
    position: relative;

    @media (min-width: 900px) {
      font-size: 32px;
      color: #fff;
      margin-bottom: 60px;
      text-align: center;
    }
  }

  .backgroundImg {
    position: absolute !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .reviews {
    margin: 0 auto;

    .swiper-container {
      width: 100%;
      overflow: hidden;
    }

    .swiper-slide {
      width: fit-content;
      margin-bottom: 15px;
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .swiper-button-prev {
      left: 10px;
      background: url(${Prev});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center right;
      height: 220px;
      top: 50%;
      transform: translateY(-50%);
      width: 70px;

      @media (max-width: 899px) {
        display: none;
      }

      &::after {
        content: none;
      }
    }

    .swiper-button-next {
      left: calc(100vw - 80px);
      background: url(${Next});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center left;
      height: 220px;
      top: 50%;
      transform: translateY(-50%);
      width: 70px;

      @media (max-width: 899px) {
        display: none;
      }

      &::after {
        content: none;
      }
    }

    .reviewBox {
      background: #FFFFFF;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.08);
      border-radius: 5px;
      max-width: 350px;
      width: calc(100vw - 50px - 50px - 30px);
      margin: 0 auto;
      padding: 25px;
      height: fit-content;

      @media (max-width: 899px) {
        padding: 20px;
      }

      .stars {
        height: 20px;
        margin-bottom: 20px;
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        margin-bottom: 25px;

        .precistCele {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #7F7F7F;
          line-height: 24px;
          text-decoration: underline;
          border: 0;
          background: none;
          padding: 0;
          cursor: pointer;
        }
      }

      .personBox {
        display: flex;
        align-items: center;

        .personalInfo {
          margin-left: 0;

          .name {
            font-family: Visuelt-Medium;
            font-size: 18px;
            color: #000000;
          }

          .location {
            font-family: Visuelt-Regular;
            font-size: 16px;
            margin-top: 6px;
            color: #7F7F7F;
          }
        }
      }
    }
  }

  a {
    background: #fff;
    position: relative;
    z-index: 1;
    color: #000000;
    margin: 0 auto;
    margin-top: 40px;

    @media (max-width: 899px) {
      margin-top: 20px;
    }

    &:hover {
      background: #dee7ef;
    }
  }
`;


const ReviewBox = ({ r }) => {

  const [isExpanded, setIsExpanded] = useState(false);
  let isTooLong = false;
  let maxChars = 350;
  let kratkaVerze = r.recenze.substr(0, maxChars);


  if (r.recenze.length > maxChars) {
    isTooLong = true;
    kratkaVerze = kratkaVerze.substr(0, Math.min(kratkaVerze.length, kratkaVerze.lastIndexOf(" ")));
  }

  const togglText = () => {
    if (isExpanded) {
      setIsExpanded(false);
    }

    else {
      setIsExpanded(true);
    }
  }

  return (
    <div className="reviewBox">
      <img className="stars" src={Stars} alt="Hodnocení" />
      
      {(isTooLong) ?
        <p>
          “{(isExpanded ? r.recenze : kratkaVerze + "...")}”
          {" "}<button className="precistCele" onClick={() => togglText()}>{(isExpanded) ? "Skrýt" : "Přečíst celé"}</button>
        </p>
      :
        <p>“{r.recenze}”</p>
      }
      <div className="personBox">
        <div className="personalInfo">
          <div className="name">{r.jmeno}</div>
          <div className="location">{r.mesto}</div>
        </div>
      </div>
    </div>
  );

}

const RecenzePohreb = ({}) => {

  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });
  
  var pocitadlo = 0;

  return (
    <ReviewsCremationInner id="reviews">

      <h2>Pro vás a vaše blízké uděláme první poslední</h2>

      <div className="reviews">

        <Swiper
          slidesPerView={"auto"}
          loop={true}
          spaceBetween={(isPlus900) ? 30 : 20}
          navigation={true}
          centeredSlides
          observer={true}
          observeParents={true}
        >

          {recenze.map((r) => {
            pocitadlo++;

            return (
              <SwiperSlide key={pocitadlo}>
                <ReviewBox r={r} />
              </SwiperSlide>
            );
          })}
          
        </Swiper>


      </div>

      <LinkButton to="/nabidka/dotaznik" text="Organizar un funeral ahora" />


      <StaticQuery
        query={graphql`
          query {
            recenzePozadi: file(relativePath: { eq: "recenzePozadi.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <Img className="backgroundImg" alt="" fluid={data.recenzePozadi.childImageSharp.fluid} />
        )}
      />

    </ReviewsCremationInner>
  );
}


export default RecenzePohreb;
